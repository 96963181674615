Ember.TEMPLATES["javascripts/connectors/user-custom-preferences/signature-preferences"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"siteSettings\",\"signatures_enabled\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"control-group signatures\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"control-label\"],[8],[1,[28,\"i18n\",[\"signatures.enable_signatures\"],null],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"controls\"],[8],[0,\"\\n      \"],[7,\"label\",true],[10,\"class\",\"checkbox-label\"],[8],[0,\"\\n        \"],[1,[28,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[24,[\"model\",\"see_signatures\"]]]]],false],[0,\"\\n        \"],[1,[28,\"i18n\",[\"signatures.show_signatures\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"control-group signatures\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"control-label\"],[8],[1,[28,\"i18n\",[\"signatures.my_signature\"],null],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"controls bio-composer input-xxlarge\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"siteSettings\",\"signatures_advanced_mode\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"d-editor\",null,[[\"value\",\"showUploadModal\"],[[24,[\"model\",\"custom_fields\",\"signature_raw\"]],\"showUploadModal\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"label\",true],[10,\"class\",\"text-label\"],[8],[0,\"\\n          \"],[1,[28,\"input\",null,[[\"type\",\"class\",\"value\"],[\"text\",\"input-xxlarge\",[24,[\"model\",\"custom_fields\",\"signature_url\"]]]]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/connectors/user-custom-preferences/signature-preferences"}});
define("discourse/plugins/discourse-signatures/discourse/initializers/extend-for-signatures", ["exports", "discourse/lib/plugin-api", "discourse/widgets/raw-html"], function (_exports, _pluginApi, _rawHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function attachSignature(api, siteSettings) {
    api.includePostAttributes("user_signature");
    api.decorateWidget("post-contents:after-cooked", function (dec) {
      var attrs = dec.attrs;

      if (Ember.isEmpty(attrs.user_signature)) {
        return;
      }

      var currentUser = api.getCurrentUser();
      var enabled;

      if (currentUser) {
        enabled = currentUser.get("custom_fields.see_signatures") ?? siteSettings.signatures_visible_by_default;
      } else {
        enabled = siteSettings.signatures_visible_by_default;
      }

      if (enabled) {
        if (siteSettings.signatures_advanced_mode) {
          return [dec.h("hr"), dec.h("div", new _rawHtml.default({
            html: "<div class='user-signature'>".concat(attrs.user_signature, "</div>")
          }))];
        } else {
          return [dec.h("hr"), dec.h("img.signature-img", {
            attributes: {
              src: attrs.user_signature
            }
          })];
        }
      }
    });
  }

  function addSetting(api) {
    api.modifyClass("controller:preferences/profile", {
      actions: {
        save: function save() {
          this.set("model.custom_fields.see_signatures", this.get("model.see_signatures"));
          this.get("saveAttrNames").push("custom_fields");

          this._super();
        }
      }
    });
  }

  var _default = {
    name: "extend-for-signatures",
    initialize: function initialize(container) {
      var siteSettings = container.lookup("site-settings:main");

      if (siteSettings.signatures_enabled) {
        (0, _pluginApi.withPluginApi)("0.1", function (api) {
          return attachSignature(api, siteSettings);
        });
        (0, _pluginApi.withPluginApi)("0.1", function (api) {
          return addSetting(api, siteSettings);
        });
      }
    }
  };
  _exports.default = _default;
});

